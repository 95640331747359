<template>
  <v-container fluid>
    <v-row class="mt-2 px-12" v-if="dataLoading">
      <v-col cols="12">
        <v-skeleton-loader type="text" max-width="200"></v-skeleton-loader>
      </v-col>

      <v-col cols="12" md="6" lg="4" v-for="i in 12" :key="i + 12">
        <v-skeleton-loader type="text"></v-skeleton-loader>
      </v-col>
      <v-col cols="12" class="mt-8">
        <v-skeleton-loader type="text" max-width="200"></v-skeleton-loader>
      </v-col>

      <v-col cols="12" md="6" lg="4" v-for="i in 12" :key="i">
        <v-skeleton-loader type="text"></v-skeleton-loader>
      </v-col>
    </v-row>

    <v-card flat outlined class="pa-sm-4 wraperx" v-else>
      <v-form @submit.prevent ref="form">
        <v-card-title class="primary--text">
          New Staff
          <v-spacer></v-spacer>
        </v-card-title>

        <v-card-text>
          <v-card flat outlined class="pa-4 pa-sm-6">
            <v-sheet class="mb-5 text-h6" color="transparent">
              Personal Information
            </v-sheet>
            <v-row class="mt-2">
              <v-col cols="12" md="6" lg="4" class="py-0">
                <v-text-field
                  flat
                  outlined
                  label="First Name *"
                  v-model="staff.first_name"
                  :rules="[rules.required, rules.alphabets]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" lg="4" class="py-0">
                <v-text-field
                  flat
                  outlined
                  label="Last Name *"
                  v-model="staff.last_name"
                  :rules="[rules.required, rules.alphabets]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" lg="4" class="py-0">
                <v-text-field
                  flat
                  outlined
                  label="Passport/NIC *"
                  v-model="staff.nic_no"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" lg="4" class="py-0">
                <v-text-field
                  flat
                  outlined
                  label="Personal Email"
                  v-model="staff.secondary_email"
                  :rules="[rules.email]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" lg="4" class="py-0">
                <v-text-field
                  flat
                  outlined
                  label="Personal Mobile *"
                  v-model="staff.primary_mobile_no"
                  :rules="[rules.required, rules.phone]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" lg="4" class="py-0">
                <v-text-field
                  flat
                  outlined
                  label="Fixed Phone"
                  v-model="staff.fixed_no"
                  :rules="[rules.phone]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" lg="4" class="py-0">
                <v-text-field
                  flat
                  outlined
                  label="Address Line 1 *"
                  v-model="staff.address_1"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" lg="4" class="py-0">
                <v-text-field
                  flat
                  outlined
                  label="Address Line 2 *"
                  v-model="staff.address_2"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" lg="4" class="py-0">
                <v-text-field
                  flat
                  outlined
                  label="City *"
                  v-model="staff.city"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" lg="4" class="py-0">
                <v-text-field
                  flat
                  outlined
                  label="State *"
                  v-model="staff.state"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" lg="4" class="py-0">
                <v-autocomplete
                  auto-select-first
                  flat
                  v-model="staff.country"
                  :rules="[rules.required]"
                  :items="countries"
                  label="Country *"
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6" lg="4" class="py-0">
                <v-text-field
                  flat
                  outlined
                  label="ZIP/Postal Code *"
                  v-model="staff.postal_code"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" lg="4" class="py-0">
                <v-autocomplete
                  auto-select-first
                  flat
                  v-model="staff.gender"
                  :rules="[rules.required]"
                  :items="genders"
                  label="Gender *"
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6" lg="4" class="py-0">
                <v-autocomplete
                  auto-select-first
                  flat
                  v-model="staff.blood_group"
                  :rules="[rules.required]"
                  :items="bloods"
                  label="Blood Group *"
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6" lg="4" class="py-0">
                <v-menu
                  :close-on-content-click="false"
                  transition="scale-transition"
                  min-width="auto"
                  v-model="menu1"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      flat
                      outlined
                      label="Date of Birth *"
                      v-model="staff.date_of_birth"
                      :rules="[rules.required]"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="staff.date_of_birth"
                    color="primary"
                    no-title
                    @input="menu1 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-card>

          <v-card flat outlined class="pa-4 pa-sm-6 mt-8">
            <v-sheet class="mb-5 text-h6" color="transparent">
              Office Related Information
            </v-sheet>
            <v-row class="mt-2">
              <v-col cols="12" md="6" lg="4" class="py-0">
                <v-text-field
                  flat
                  outlined
                  label="Staff No *"
                  v-model="staff.staff_no"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" lg="4" class="py-0">
                <v-text-field
                  flat
                  outlined
                  label="EPF No"
                  v-model="staff.epf_no"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" lg="4" class="py-0">
                <v-text-field
                  flat
                  outlined
                  label="Office Email *"
                  v-model="staff.primary_email"
                  :rules="[rules.required, rules.email]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" lg="4" class="py-0">
                <v-text-field
                  flat
                  outlined
                  label="Office Mobile"
                  v-model="staff.secondary_mobile_no"
                  :rules="[rules.phone]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" lg="4" class="py-0">
                <v-text-field
                  flat
                  outlined
                  label="Designation *"
                  v-model="staff.designation"
                  :rules="[rules.required, rules.alphabets]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" lg="4" class="py-0">
                <v-autocomplete
                  auto-select-first
                  flat
                  v-model="staff.role_uuid"
                  :rules="[rules.required]"
                  :items="roles"
                  item-text="name"
                  item-value="uuid"
                  label="Role *"
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6" lg="4" class="py-0">
                <v-autocomplete
                  auto-select-first
                  flat
                  v-model="staff.type"
                  :rules="[rules.required]"
                  :items="staffTypes"
                  label="Type *"
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6" lg="4" class="py-0">
                <v-autocomplete
                  auto-select-first
                  flat
                  v-model="staff.immediate_supervisor_uuid"
                  :items="supervisors"
                  item-text="name"
                  item-value="uuid"
                  label="Immediate Supervisor"
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6" lg="4" class="py-0">
                <v-menu
                  :close-on-content-click="false"
                  transition="scale-transition"
                  bottom
                  min-width="auto"
                  v-model="menu2"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      flat
                      outlined
                      label="Joined Date *"
                      v-model="staff.date_of_joining"
                      :rules="[rules.required]"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="staff.date_of_joining"
                    color="primary"
                    no-title
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="6" lg="4" class="py-0">
                <v-text-field
                  flat
                  outlined
                  label="Age at Joining"
                  v-model="staff.age_at_joining"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" lg="4" class="py-0">
                <v-text-field
                  v-if="staff.left_at"
                  flat
                  outlined
                  label="Left Date *"
                  v-model="staff.left_at"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card>
        </v-card-text>

        <v-card-title>
          <v-spacer></v-spacer>
          <v-btn color="secondary" depressed @click="goBack" text class="mx-3">
            cancel
          </v-btn>
          <v-btn
            color="primary"
            depressed
            @click="createStaff"
            :loading="loading"
          >
            Create
          </v-btn>
        </v-card-title>
      </v-form>
    </v-card>
  </v-container>
</template>
    
    
    <script>
import { mapState } from "vuex";
import { v4 as uuidv4 } from "uuid";
import {
  fetchAndStoreStaffData,
  fetchAndStoreRoleSData,
} from "@/services/preloadDataService";

import { addStaff } from "@/services/staffService";

import constants from "@/constants.json";

export default {
  components: {
    //
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  data: () => ({
    menu1: false,
    menu2: false,
    loading: false,
    dataLoading: false,
    rules: {
      required: (value) => !!value || "Required.",
      email: (value) => {
        if (!value) return true;
        return /.+@.+\..+/.test(value) || "Invalid e-mail.";
      },
      alphabets: (value) => {
        if (!value) return true;
        return (
          /^[A-Za-z\s]+$/.test(value) ||
          "Invalid input; only alphabetic letters are allowed."
        );
      },
      phone: (value) => {
        if (!value) return true;
        return /^[0-9+\-()\s]{9,15}$/.test(value) || "Invalid phone number.";
      },
    },
    genders: constants.genders,
    countries: constants.countries,
    bloods: constants.bloodGroups,
    staffTypes: constants.staffTypes,
    supervisors: [],
    roles: [],

    staff: {
      uuid: "",
      staff_no: "",
      first_name: "",
      last_name: "",
      primary_email: "",
      secondary_email: "",
      gender: "",
      designation: "",
      date_of_joining: "",
      age_at_joining: "",
      nic_no: "",
      address_1: "",
      address_2: "",
      city: "",
      state: "",
      country: "",
      postal_code: "",
      primary_mobile_no: "",
      secondary_mobile_no: "",
      epf_no: "",
      date_of_birth: "",
      blood_group: "",
      immediate_supervisor_uuid: "",
      left_date: "",
      fixed_no: "",
      role_uuid: "",
      staff: {},
      roles: {},
      type: "",
    },

    addStaffReturn: null,
  }),
  async created() {
    if (
      this.$_checkPermission(this.auth.permissions, "Manage Staff", "Create")
    ) {
      this.dataLoading = true;
      this.supervisors = await fetchAndStoreStaffData();
      this.supervisors.forEach((supervisor) => {
        supervisor["name"] = supervisor.first_name + " " + supervisor.last_name;
      });
      this.roles = await fetchAndStoreRoleSData();
      this.dataLoading = false;
    } else {
      this.$router.push({
        name: "PageDashboard",
      });
    }
  },
  mounted() {
    //
  },
  methods: {
    goBack() {
      this.$router.push({ name: "PageStaffList" });
    },
    viewItem(item) {
      this.$router.push({
        name: "PageViewStaff",
        params: { uuid: item.uuid },
        query: { bc: item.first_name + " " + item.last_name },
      });
    },
    async createStaff() {
      if (this.$refs.form.validate()) {
        this.staff.uuid = uuidv4();
        this.staff.staff = this.supervisors.find(
          (supervisor) =>
            supervisor.uuid == this.staff.immediate_supervisor_uuid
        );
        this.staff.roles = this.roles.find(
          (role) => role.uuid == this.staff.role_uuid
        );
        this.loading = true;
        this.addStaffReturn = await addStaff(this.staff);
        this.loading = false;
        if (this.addStaffReturn == "success") {
          this.viewItem(this.staff);
        }
      } else {
        this.$store.commit("setError", {
          isError: true,
          error:
            "Some fields are missing or incorrect. Please check your input.",
        });
      }
    },
  },
  updated() {
    this.staff.age_at_joining = this.$_calculateAgeAtJoining(
      this.staff.date_of_joining,
      this.staff.date_of_birth
    );
  },
};
</script>